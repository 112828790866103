import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import "./output.css"

function Header({siteTitle}) {
  const [hidden, setHidden] = useState(true)

  return (
  <nav className="flex items-center justify-between flex-wrap bg-green-500 p-6 mb-6">
    <div className="flex items-center flex-shrink-0 text-white mr-6">
      <Link to="/" className="font-semibold text-xl tracking-tight">{siteTitle}</Link>
    </div>
    <div className="block lg:hidden">
      { hidden ?
          (<button className="flex items-center px-3 py-2 border rounded text-green-200 border-green-4000 hover:text-white hover:border-white" onClick={() => setHidden(false)}>
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>) : 
          (<button className="flex items-center px-3 py-2 border rounded text-green-200 border-green-4000 hover:text-white hover:border-white" onClick={() => setHidden(true)}>
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>)}
    </div>
    <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${hidden && 'hidden'}`}>
      <Link to="/rules" className="inline-block mt-4 lg:mt-0 text-green-200 hover:text-white mr-4">
        Rules
      </Link>
      <Link to="/about" className="block mt-4 lg:inline-block lg:mt-0 text-green-200 hover:text-white">
        About
      </Link>
    </div>
  </nav>
  )
  }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
